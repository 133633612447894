import { FC, ReactElement, useState, ChangeEvent, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { FormWrapper } from '../FormWrapper';
import { COLORS } from '../../../../constants/colors';
import { CustomerDto1, RepresentativeDto } from '../../../../types/api';
import { RegistrationSteps } from '../../../../constants/registrationSteps';
import axios from '../../../../utils/axios';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../selectors/selectors';
import { useScreenSize } from '../../../../hooks';
import { addSnackbar } from '../../../../store/slices/snackbarsSlice';

interface IICODataProps {
  title: string;
  footer: ReactElement;
  moveToStep: (step: RegistrationSteps, saveHistory?: boolean) => void;
}

export const ICOData: FC<IICODataProps> = ({ title, footer, moveToStep }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentRepresentativeId, setCurrentRepresentativeId] = useState('');
  const [companyRepresentatives, setCompanyRepresentatives] = useState<
    Array<RepresentativeDto>
  >([]);
  const [currentCustomer, setCurrentCustomer] = useState<CustomerDto1 | null>(
    null
  );
  const [stopRequests, setStopRequests] = useState<boolean>(false);
  const { isMediumScreen } = useScreenSize();
  const { currentUser } = useAppSelector((state) => state.currentUser);
  const dispatch = useAppDispatch();
  const customerId = currentUser?.customers?.[0]?.id;

  const getCurrentRepresentatives = async () => {
    try {
      setLoading(true);
      if (!customerId) {
        return;
      }
      const currentCustomerData = await axios.get<CustomerDto1>(
        `customers/${customerId}`
      );
      const companyRepresentatives = await axios.get<Array<RepresentativeDto>>(
        `/customers/${customerId}/representatives`
      );

      // Check if both currentCustomerData and companyRepresentatives are available
      if (
        currentCustomerData &&
        companyRepresentatives &&
        companyRepresentatives.data.length > 0
      ) {
        // Stop further requests by setting stopRequests to true
        setStopRequests(true);
        setLoading(false);

        const filteredCompanyRepresentatives =
          companyRepresentatives?.data?.filter(
            (comp) => comp.id !== currentUser.partyId
          );

        setCurrentRepresentativeId(
          filteredCompanyRepresentatives?.[0]?.id ?? 'other'
        );
        setCurrentCustomer(currentCustomerData.data);

        setCompanyRepresentatives(filteredCompanyRepresentatives);
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        addSnackbar({
          variant: 'error',
          message: t('registration.errors.general-registration-error'),
        })
      );
    }
  };

  useEffect(() => {
    if (currentUser && !stopRequests) {
      const makeRequest = async () => {
        try {
          await getCurrentRepresentatives();
        } catch (error) {
          dispatch(
            addSnackbar({
              variant: 'error',
              message: t('registration.errors.general-registration-error'),
            })
          );
        }
      };

      const id = setInterval(makeRequest, 5000);

      setTimeout(() => {
        clearInterval(id);
        setStopRequests(true);
        setLoading(false);
      }, 120000); // 2 minutes in milliseconds

      makeRequest();

      return () => {
        clearInterval(id);
      };
    }
  }, [currentUser, stopRequests]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentRepresentativeId((event.target as HTMLInputElement).value);
  };
  const onSubmit = async () => {
    if (currentRepresentativeId === 'other') {
      moveToStep(RegistrationSteps.NewExecutive);
    } else {
      try {
        setLoading(true);
        await axios.post(
          `customers/${customerId}/link-user-representative/${currentRepresentativeId}`
        );
        setLoading(false);
        moveToStep(RegistrationSteps.Phone);
      } catch (error) {
        setLoading(false);

        dispatch(
          addSnackbar({
            variant: 'error',
            message: t('registration.errors.general-registration-error'),
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const {
    organization: {
      address: { line1 = '', city = '', country = '', zip = '' } = {},
    } = {},
  } = currentCustomer ?? {};

  const representativesFound = !loading && companyRepresentatives.length;
  const representativesLoading = loading && !companyRepresentatives.length;

  return (
    <FormWrapper
      title={title}
      footer={footer}
      currentStep={4}
      onSubmit={onSubmit}
      loading={loading}
      omitContinueButton={!companyRepresentatives.length}
    >
      {representativesFound ? (
        <FormControl sx={{ width: '100%' }}>
          <Box
            sx={{ paddingLeft: isMediumScreen ? '3.5rem' : 0, width: '95%' }}
          >
            <Typography variant="h5" fontWeight="bold" mb={2}>
              {t('registration.registration-subject')}
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Box flex="1">
                <Typography variant="body1">
                  {currentCustomer?.organization.name}
                </Typography>
              </Box>
              {isMediumScreen && (
                <Box flex="1" minWidth="10rem" ml={1}>
                  <Typography variant="body1">
                    {t('registration.registration-company-name')}
                  </Typography>
                </Box>
              )}
            </Box>
            <Divider sx={{ mb: 1, width: '90%' }} />
            <Box display="flex" justifyContent="space-between">
              <Box flex="1">
                <Typography variant="body1">
                  {`${line1} ${city} ${country} ${zip}`}
                </Typography>
              </Box>
              {isMediumScreen && (
                <Box flex="1" minWidth="10rem" ml={1}>
                  <Typography variant="body1">{t('common-office')}</Typography>
                </Box>
              )}
            </Box>
            <Divider sx={{ width: '90%' }} />
            <Typography variant="h5" fontWeight="bold" mb={2} mt={4}>
              {t('registration.registration-executive')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '15rem',
              overflowY: companyRepresentatives.length >= 2 ? 'scroll' : 'none',
            }}
          >
            <RadioGroup
              value={currentRepresentativeId}
              onChange={handleChange}
              sx={{ width: '100%' }}
            >
              <FormGroup>
                {companyRepresentatives.map((rep) => {
                  const {
                    dob = '',
                    address: { line1 = '', city = '', country = '', zip = '' },
                  } = rep ?? {};

                  return (
                    <FormControlLabel
                      key={rep.id}
                      sx={{
                        border:
                          rep.id === currentRepresentativeId
                            ? `2px solid ${COLORS.primary}`
                            : 'none',
                        borderRadius: '10px',
                        padding: '1rem',
                        marginLeft: 0,
                        width: '100%',
                      }}
                      value={rep.id}
                      control={<Radio />}
                      componentsProps={{ typography: { width: '100%' } }}
                      label={
                        <>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            sx={{ width: '100%' }}
                          >
                            <Box flex="1">
                              <Typography variant="body1">{`${rep.firstName} ${rep.lastName}`}</Typography>
                            </Box>
                            {isMediumScreen && (
                              <Box flex="1" minWidth="10rem">
                                <Typography variant="body1">
                                  {t('business-name')}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                          <Divider sx={{ mb: 1, width: '90%' }} />
                          <Box display="flex" justifyContent="space-between">
                            <Box flex="1">
                              <Typography variant="body1">
                                {`${line1} ${city} ${country} ${zip}`}
                              </Typography>
                            </Box>
                            {isMediumScreen && (
                              <Box flex="1" minWidth="10rem">
                                <Typography variant="body1">
                                  {t('business-address')}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                          <Divider sx={{ mb: 1, width: '90%' }} />

                          <Box display="flex" justifyContent="space-between">
                            <Box flex="1">
                              <Typography variant="body1">
                                {dob
                                  ? moment(dob).format('DD.MM.YYYY')
                                  : t('common-not-abailable')}
                              </Typography>
                            </Box>

                            {isMediumScreen && (
                              <Box flex="1" minWidth="10rem">
                                <Typography variant="body1">
                                  {t('common-birthday')}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </>
                      }
                    />
                  );
                })}

                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={t('registration.registration-add-new-executive')}
                  sx={{
                    padding: '1rem 1rem 0 1rem',
                    marginLeft: 0,
                    width: '100%',
                  }}
                />
              </FormGroup>
            </RadioGroup>
          </Box>
        </FormControl>
      ) : representativesLoading ? (
        t('registration.fetching-from-register')
      ) : (
        t('registration.errors.ico-not-found-error')
      )}
    </FormWrapper>
  );
};
