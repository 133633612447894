export interface ICustomPartner {
  name: string;
  url: string | null;
}

export const customPartners: Array<ICustomPartner> = [
  {
    name: 'Storyous',
    url: 'https://admin.storyous.com/cz/bills/bills/flow-pay/',
  },
  { name: 'Inventoro', url: null },
  { name: 'Dropshipping', url: null },
  { name: 'Shoptet', url: 'https://www.shoptetboost.cz/' },
  { name: 'Tanganica', url: null },
  { name: 'Shopify', url: null },
];

export const getHoToLinks = (lng: string) => {
  const isCzechLanguage = lng === 'cs';
  return [
    {
      name: 'Dotykačka',
      url: isCzechLanguage
        ? 'https://www.support.flowpay.io/cs/dotykacka'
        : 'https://www.support.flowpay.io/dotykacka',
    },
    {
      name: 'Dropshipping',
      url: null,
    },
    {
      name: 'Google analytics',
      url: isCzechLanguage
        ? 'https://www.support.flowpay.io/cs/googleanalytics'
        : 'https://www.support.flowpay.io/googleanalytics',
    },
    {
      name: 'Inventoro',
      url: null,
    },
    {
      name: 'Magento V1',
      url: isCzechLanguage
        ? 'https://www.support.flowpay.io/cs/magentov1'
        : 'https://www.support.flowpay.io/magentov1',
    },
    {
      name: 'Magento V2',
      url: isCzechLanguage
        ? 'https://www.support.flowpay.io/cs/magento'
        : 'https://www.support.flowpay.io/magento',
    },
    {
      name: 'Prestashop',
      url: isCzechLanguage
        ? 'https://www.support.flowpay.io/cs/prestashop'
        : 'https://www.support.flowpay.io/prestashop',
    },
    {
      name: 'Shopify',
      url: isCzechLanguage
        ? 'https://www.support.flowpay.io/cs/shopify'
        : 'https://www.support.flowpay.io/shopify',
    },
    {
      name: 'Shoptet',
      url: 'https://www.shoptetboost.cz/',
      showDialog: true,
    },
    {
      name: 'Storyous',
      url: 'https://admin.storyous.com/cz/bills/bills/flow-pay/',
      showDialog: true,
    },
    {
      name: 'Tanganica',
      url: 'https://tanganica.cz?utm_source=flowpay',
      showDialog: true,
    },
    {
      name: 'Woocommerce',
      url: isCzechLanguage
        ? 'https://www.support.flowpay.io/cs/woocommerce'
        : 'https://www.support.flowpay.io/woocommerce',
    },
  ];
};
