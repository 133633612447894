import {
  FC,
  useState,
  ChangeEvent,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import axios from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { Address, CountryDto } from '../../types/api';

export interface IUBOPerson {
  firstName: string;
  lastName: string;
  dob: string;
  nationality: string;
  address: Address;
}

interface AddressFormProps {
  formData: IUBOPerson;
  setFormData: Dispatch<SetStateAction<IUBOPerson>>;
  errors: IUBOPerson;
}

const AddressForm: FC<AddressFormProps> = ({
  formData,
  setFormData,
  errors,
}) => {
  const [countries, setCountries] = useState<Array<CountryDto>>([]);
  const [countryInputValue, setCountryInputValue] = useState<string>('');
  const [nationalityInputValue, setNationalityInputValue] =
    useState<string>('');

  const { t } = useTranslation();

  useEffect(() => {
    const getCountries = async () => {
      try {
        const res = await axios.get('parties/countries');
        setCountries(res.data);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    getCountries();
  }, []);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name.startsWith('address.')) {
      const addressField = name.replace('address.', '');
      setFormData((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          [addressField]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAutocompleteChange = (
    name: string,
    newValue: CountryDto | null
  ) => {
    const value = newValue?.code ?? '';

    if (name === 'nationality') {
      setFormData((prevState) => ({
        ...prevState,
        nationality: value,
      }));
      setNationalityInputValue(newValue?.name ?? ''); // Set display name
    } else if (name === 'address.country') {
      setFormData((prevState) => ({
        ...prevState,
        address: {
          ...prevState.address,
          country: value,
        },
      }));
      setCountryInputValue(newValue?.name ?? ''); // Set display name for address
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid md={6} xs={12}>
        <TextField
          size="small"
          label={t('registration-form-firstname')}
          fullWidth
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          error={!!errors.firstName}
          helperText={errors.firstName}
        />
      </Grid>
      <Grid md={6} xs={12}>
        <TextField
          size="small"
          label={t('registration-form-lastname')}
          fullWidth
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          error={!!errors.lastName}
          helperText={errors.lastName}
        />
      </Grid>
      <Grid md={6} xs={12}>
        <TextField
          size="small"
          type="date"
          label={t('registration-form-birthday')}
          InputLabelProps={{ shrink: true }}
          fullWidth
          name="dob"
          value={formData.dob}
          onChange={handleInputChange}
          error={!!errors.dob}
          helperText={errors.dob}
        />
      </Grid>
      <Grid md={6} xs={12}>
        <Autocomplete
          disablePortal
          options={countries}
          getOptionLabel={(option) => option?.name ?? ''}
          size="small"
          isOptionEqualToValue={(option, value) => option.code === value.code}
          value={
            countries.find(
              (country: any) => country.code === formData.nationality
            ) || null
          }
          onChange={(event, newValue) =>
            handleAutocompleteChange('nationality', newValue)
          }
          inputValue={nationalityInputValue}
          onInputChange={(event, newInputValue) =>
            setNationalityInputValue(newInputValue)
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                alt="Country flag"
                style={{ width: '30px', height: '20px' }}
              />
              {option.name} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('common-nationality')}
              error={!!errors.nationality}
              helperText={errors.nationality}
            />
          )}
        />
      </Grid>
      <Grid md={6} xs={12}>
        <Autocomplete
          disablePortal
          options={countries}
          getOptionLabel={(option) => option?.name ?? ''}
          size="small"
          isOptionEqualToValue={(option, value) => option.code === value.code}
          value={
            countries.find(
              (country) => country.code === formData.address.country
            ) || null
          }
          onChange={(event, newValue) =>
            handleAutocompleteChange('address.country', newValue)
          }
          inputValue={countryInputValue}
          onInputChange={(event, newInputValue) =>
            setCountryInputValue(newInputValue)
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
                alt="Country flag"
                style={{ width: '30px', height: '20px' }}
              />
              {option.name} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('common-country')}
              error={!!errors.address?.country}
              helperText={errors.address?.country}
            />
          )}
        />
      </Grid>
      <Grid md={6} xs={12}>
        <TextField
          size="small"
          label={t('registration-form-city')}
          fullWidth
          name="address.city"
          value={formData.address.city}
          onChange={handleInputChange}
          error={!!errors.address?.city}
          helperText={errors.address?.city}
        />
      </Grid>
      <Grid md={8} xs={12}>
        <TextField
          size="small"
          label={t('registration-form-street')}
          fullWidth
          name="address.line1"
          value={formData.address.line1}
          onChange={handleInputChange}
          error={!!errors.address?.line1}
          helperText={errors.address?.line1}
        />
      </Grid>
      <Grid md={4} xs={12}>
        <TextField
          size="small"
          label={t('registration-form-post')}
          fullWidth
          name="address.zip"
          value={formData.address.zip}
          onChange={handleInputChange}
          error={!!errors.address?.zip}
          helperText={errors.address?.zip}
        />
      </Grid>
    </Grid>
  );
};

export default AddressForm;
