import i18n from 'i18next';
import { IsoCurrencyCode } from '../types/api';

export function formatDate(date?: Date | string) {
  if (!date) return '';

  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('.');
}

export function formatMoney(value: unknown, currency = IsoCurrencyCode.CZK) {
  let locales;
  switch (i18n.language) {
    case 'cs':
      locales = 'cs-CS';
      break;
    case 'en':
      locales = 'en-US';
      break;
    case 'nl':
      locales = 'nl-NL';
      break;
    default:
      locales = 'cs-CS';
      break;
  }
  const val = value ? +value : 0;

  return val.toLocaleString(locales, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function formatCurrency(currency: IsoCurrencyCode) {
  let locales;
  switch (i18n.language) {
    case 'cs':
      locales = 'cs-CS';
      break;
    case 'en':
      locales = 'en-US';
      break;
    case 'nl':
      locales = 'nl-NL';
      break;
    default:
      locales = 'cs-CS';
      break;
  }

  const formatted = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(0);

  return formatted.replace(/\d/g, '').trim();
}

export function kFormatter(num: number) {
  return Math.abs(num) > 999
    ? (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
}

export function formatDayString(days: number) {
  let str = i18n.t('common-days-alt');
  if (days === 1) {
    str = i18n.t('common-day');
  } else if (days > 1 && days < 5) {
    str = i18n.t('common-days');
  }
  return `${days} ${str}`;
}
