import { useState, useEffect, FC, SyntheticEvent, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Connections as HotGlue, useHotglue } from '@hotglue/widget';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@mui/material/Typography';

import axios from '../../utils/axios';
import { useLoading } from '../../context/loading-context';
import { useErrorHandler } from '../../context/error-handler-context';

import Snackbar from '@mui/material/Snackbar';

import Button from '../UI/Button';
import ConnectionDialog from '../Dialogs/ConnectionDialog';
import ConnectionPSD2Dialog from '../Dialogs/ConnectionPSD2Dialog';
import NewConnectionDialog from '../Dialogs/NewConnectionDialog';
import PSD2Image from '../../assets/partners/PSD2.svg';

import styles from './Connections.module.scss';
import './HotGlue.scss';
import { useQuery } from '../../hooks';
import Alert from '@mui/material/Alert';
import {
  ICustomPartner,
  customPartners,
  getHoToLinks,
} from '../../constants/connections';
import { RobotImage } from '../UI/RobotImage';
import ShopifyDialog from '../Dialogs/ShopifyDialog';
import { useAppDispatch, useAppSelector } from '../../selectors/selectors';
import { setCurrentUserAction } from '../../store/slices/currentUserSlice';
import { addDialog, removeDialog } from '../../store/slices/dialogsSlice';
import HotGlueDialog from '../Dialogs/HotGlueDialog';
import { addSnackbar } from '../../store/slices/snackbarsSlice';
import { Languages } from '../../enums/languages';

const envId = process.env.REACT_APP_HOTGLUE_ENV;
const isShopifyEnabled = process.env.REACT_APP_SHOPIFY_ENABLED === 'true';

interface IConnectionsProps {
  customerId?: string;
  doConnectShopify?: boolean;
}

const Connections: FC<IConnectionsProps> = ({
  customerId,
  doConnectShopify,
}) => {
  const { t } = useTranslation();
  const { setListener } = useHotglue();
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => state.currentUser);
  const { currentCustomer } = useAppSelector((state) => state.currentCustomer);
  const systemLanguage = useAppSelector((state) => state.language);

  const { state, step } = useParams();

  const [open, setOpen] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openPSD2, setOpenPSD2] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<ICustomPartner | null>(
    null
  );
  const [openAlert, setOpenAlert] = useState<string>('');

  const [bankConnections, setBankConnections] = useState<Array<any>>([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const tenantId = searchParams.get('tenantId');

  const query = useQuery();
  const ref = query.get('ref');
  const queryError = query.get('error');

  const partners =
    currentUser?.customers?.find((c) => c.id === customerId)?.partners ?? [];
  const partnersName = partners ? partners.map((p) => p.name) : [];

  const filteredCustomPartners = useMemo(() => {
    return isShopifyEnabled
      ? customPartners
      : customPartners.filter((partner) => partner.name !== 'Shopify');
  }, []);

  const handleClickOpen = (selectedPartner: ICustomPartner) => {
    setSelectedPartner(selectedPartner);
    setOpen(true);
  };

  const handleClickOpenNew = () => {
    setOpenNew(true);
  };

  const handleClose = (value: boolean) => {
    setSelectedPartner(null);
    setOpen(false);
    if (value) setOpenAlert(t('connections-dialog-success'));
  };

  const handleCloseNew = (value: boolean) => {
    setOpenNew(false);
    if (value) setOpenAlert(t('connections-dialog-success'));
  };

  const handleCloseAlert = (
    event: SyntheticEvent<Element, Event> | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert('');
  };

  const showHotGlueDialog = () => {
    const id = Date.now();

    dispatch(
      addDialog({
        component: HotGlueDialog,
        id,
        props: {
          content: (
            <>
              <Typography>
                {t('application-partner-hotglue-dialog-body')}
              </Typography>
              <br />
              <Typography>
                {t('application-partner-hotglue-dialog-body2')}
              </Typography>
            </>
          ),
          onClose: () => {
            dispatch(removeDialog(id));
          },
          open: true,
          title: t('application-partner-hotglue-dialog-header'),
        },
      })
    );
  };

  useEffect(() => {
    if (customerId) {
      setListener({
        //@ts-ignore
        onSourceLinked: (source, flow) => {
          const tap = source.tap;
          setLoading(true);

          axios
            .post(
              `/partner-connections/customers/${customerId}/hotglue-connection`,
              {
                env: envId,
                flow: flow,
                tap: tap,
              }
            )
            .then(() => {
              setLoading(false);
              showHotGlueDialog();
            })
            .catch((err) => {
              setLoading(false);
              setError(err);
            });
        },
      });
    }
  }, [setError, setListener, setLoading, customerId]);

  useEffect(() => {
    if (customerId) {
      setLoading(true);
      axios
        .get(`customers/${customerId}/psd-connections`)
        .then((res) => {
          const connections = res.data
            ? res.data.filter((b: any) => b.bankId)
            : [];
          setBankConnections(connections);
          setLoading(false);

          if (step === 'psd' && state === 'completed' && ref && !queryError) {
            callPsdIsComplete();
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    }
  }, [customerId]);

  useEffect(() => {
    if (tenantId) {
      let currentCustomerId = customerId;
      if (!customerId && doConnectShopify) {
        try {
          currentCustomerId = JSON.parse(
            localStorage.getItem('customer_id') ?? ''
          );
        } catch (error) {
          dispatch(addSnackbar({ variant: 'error', message: t('error-info') }));
        }
      }
      const data = {
        customerId: currentCustomerId,
        tenantId,
      };

      setLoading(true);
      axios
        .post(`partner-connections/shopify`, data)
        .then(() => {
          axios
            .get('users/current')
            .then((res) => {
              dispatch(setCurrentUserAction(res.data));
              setLoading(false);
              navigate(`/${currentCustomerId}/connections`);
              showHotGlueDialog();
            })
            .catch((e) => {
              console.error(e);
            });
        })
        .catch((err) => {
          setLoading(false);
          //  dispatch(addSnackbar({ variant: 'error', message: t('error-info') }));
        });
    }
  }, [tenantId]);

  // useEffect(() => {
  //   if(!customerId && doConnectShopify) {
  //     const currentCustomerId = localStorage.getItem('') //
  //   }
  // }, [customerId, doConnectShopify])

  const callPsdIsComplete = () => {
    setLoading(true);
    axios
      .post(`customers/${customerId}/psd-connections/${ref}/complete`, {})
      .then((res) => {
        switch (res.data.state) {
          case 'PENDING':
            setTimeout(() => {
              setLoading(false);
              callPsdIsComplete();
            }, 15 * 1000);
            break;

          case 'FAILED':
            setLoading(false);
            setError(t('error-info'));
            break;

          case 'ACTIVE':
            setLoading(false);
            setOpenAlert('Banka byla úspěšně napojena');
            setBankConnections([{ bankId: 'new' }]);
            navigate(`/${customerId}/connections`, { replace: true });
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const howToLinks = getHoToLinks(systemLanguage);

  const supportUrl =
    systemLanguage === Languages.CZ
      ? 'https://www.support.flowpay.io/cs/'
      : 'https://www.support.flowpay.io/';

  return (
    <div className={styles['connections-wrapper']}>
      {currentCustomer?.partners?.length ? (
        <div className={styles['info-wrapper']}>
          <h2>{t('connections-title')}</h2>
          <p>
            <Trans
              i18nKey="connections-info"
              components={[<br />, <span></span>]}
            ></Trans>
          </p>
        </div>
      ) : (
        <div className={styles['welcome-wrapper']}>
          <RobotImage />
          <div className="gradient-bg">
            <h2>{t('connections-title-alt')}</h2>
          </div>
        </div>
      )}

      <div className={styles['wrapper']}>
        <div className={styles['partners-wrapper']}>
          {/* <div className={styles.info}>
          <span className={styles.title}>{t('connections-new-title')}</span>
          <span className={styles.text}>{t('connections-new-info')}</span>

          <Button className='gradient partner' onClick={handleClickOpenNew}>
            <span>{t('connections-new-button')}</span>
          </Button>
        </div> */}

          {open &&
            (selectedPartner?.name === 'Shopify' ? (
              <ShopifyDialog
                open={open}
                selectedPartner={selectedPartner}
                onClose={handleClose}
              />
            ) : (
              <ConnectionDialog
                customerId={customerId ?? ''}
                open={open}
                selectedPartner={selectedPartner}
                currentUser={currentUser!}
                onClose={handleClose}
              />
            ))}
          {openPSD2 && (
            <ConnectionPSD2Dialog
              customerId={customerId ?? ''}
              open={openPSD2}
              onClose={() => setOpenPSD2(false)}
            />
          )}
          {openNew && (
            <NewConnectionDialog
              customerId={customerId ?? ''}
              open={openNew}
              onClose={handleCloseNew}
            />
          )}

          <div className={styles['partner-box-wrapper']}>
            {filteredCustomPartners.map((p) => {
              const image = require(`../../assets/partners/${p.name}.svg`);

              return (
                <div
                  className={`${styles['partner-box']} ${
                    partnersName.includes(p.name) ? styles.active : ''
                  }`}
                  key={p.name}
                >
                  <div className={styles.partner}>
                    <img src={image} title={p.name} alt={p.name} />
                  </div>

                  <button
                    tabIndex={0}
                    type="button"
                    onClick={() => handleClickOpen(p)}
                  >
                    <svg
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AddIcon"
                    >
                      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                    </svg>
                  </button>

                  <p className={styles['active']}>Linked</p>
                </div>
              );
            })}

            <div
              className={`${styles['partner-box']} ${styles['psd']} ${
                bankConnections && bankConnections.length ? styles.active : ''
              }`}
            >
              <div className={styles.partner}>
                <img src={PSD2Image} title="PSD2" alt="PSD2" />
                <span>{t('connections-psd2-bank-connection')}</span>
              </div>

              <button
                tabIndex={0}
                type="button"
                onClick={() => setOpenPSD2(true)}
              >
                <svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="AddIcon"
                >
                  <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                </svg>
              </button>

              <p className={styles['active']}>Linked</p>

              <button
                tabIndex={0}
                type="button"
                className={styles.settings}
                onClick={() => setOpenPSD2(true)}
              >
                <svg
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="SettingsIcon"
                >
                  <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58c.18-.14.23-.41.12-.61l-1.92-3.32c-.12-.22-.37-.29-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54c-.04-.24-.24-.41-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58c-.18.14-.23.41-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z"></path>
                </svg>
              </button>
            </div>
          </div>

          <HotGlue
            tenant={customerId ?? ''}
            hideBackButtons={true}
            multipleSources={true}
            filterEntity={(flowId, entityId, isTarget) => {
              const commonExclusions =
                entityId !== 'shoptet-flowpay' && entityId !== 'storyous';

              const shopifyExclusion =
                isShopifyEnabled && entityId === 'shopify';

              return commonExclusions && !shopifyExclusion;
            }}
          />

          <div className={styles['new']}>
            <div className={styles['title-wrapper']}>
              <span className={styles.title}>
                <Trans
                  i18nKey="connections-new-title"
                  components={[<span></span>]}
                ></Trans>
              </span>
              <Button className="gradient partner" onClick={handleClickOpenNew}>
                <span>{t('connections-new-button')}</span>
              </Button>
            </div>
            <span className={styles.text}>{t('connections-new-info')}</span>

            <Button className="gradient partner" onClick={handleClickOpenNew}>
              <span>{t('connections-new-button')}</span>
            </Button>
          </div>

          <div className={styles['how-to-mobile']}>
            <div className={styles['title-wrapper']}>
              <span className={styles.title}>
                {t('connections-new-how-connect')}
              </span>
              <Button
                className="gradient partner"
                onClick={() => window.open(supportUrl, '_blank')}
              >
                <span>{t('connections-new-help')}</span>
              </Button>
            </div>
            <span className={styles.text}>
              {t('connections-new-help-info')}
            </span>

            <Button
              className="gradient partner"
              onClick={() => window.open(supportUrl, '_blank')}
            >
              <span>{t('connections-new-help')}</span>
            </Button>
          </div>

          {/* <div className={`${styles['partner-box']} ${styles.active}`}>
            <div className={styles.partner}>
              <img src={partnerLogo} title='Partner logo' alt='Partner logo' />
              <span>Online pokladní systém pro restaurace.</span>
              <p className={styles['active']}>Aktivní</p>
            </div>

            <Button className='gradient partner'>
              <span>Odpojit poskytovatele</span>
            </Button>
          </div>

          <div className={`${styles['partner-box']} ${styles.active}`}>
            <div className={styles.partner}>
              <img src={partnerLogo} title='Partner logo' alt='Partner logo' />
              <span>Online pokladní systém pro restaurace.</span>
              <p className={styles['active']}>Aktivní</p>
            </div>

            <Button className='gradient partner'>
              <span>Odpojit poskytovatele</span>
            </Button>
          </div>

          <div className={styles['partner-box']}>
            <div className={styles.partner}>
              <img src={partnerLogo} title='Partner logo' alt='Partner logo' />
              <span>Online pokladní systém pro restaurace.</span>
            </div>

            <Button className='gradient partner'>
              <span>Připojit poskytovatele</span>
            </Button>
          </div>

          <div className={styles['partner-box']}>
            <div className={styles.partner}>
              <img src={partnerLogo} title='Partner logo' alt='Partner logo' />
              <span>Online pokladní systém pro restaurace.</span>
            </div>

            <Button className='gradient partner'>
              <span>Připojit poskytovatele</span>
            </Button>
          </div>

          <div className={styles['partner-box']}>
            <div className={styles.partner}>
              <img src={partnerLogo} title='Partner logo' alt='Partner logo' />
              <span>Online pokladní systém pro restaurace.</span>
            </div>

            <Button className='gradient partner'>
              <span>Připojit poskytovatele</span>
            </Button>
          </div>

          <div className={styles['partner-box']}>
            <div className={styles.partner}>
              <img src={partnerLogo} title='Partner logo' alt='Partner logo' />
              <span>Online pokladní systém pro restaurace.</span>
            </div>

            <Button className='gradient partner'>
              <span>Připojit poskytovatele</span>
            </Button>
          </div>

          <div className={styles['partner-box']}>
            <div className={styles.partner}>
              <img src={partnerLogo} title='Partner logo' alt='Partner logo' />
              <span>Online pokladní systém pro restaurace.</span>
            </div>

            <Button className='gradient partner'>
              <span>Připojit poskytovatele</span>
            </Button>
          </div> */}
        </div>

        <div className={styles['how-to']}>
          <span className={styles.title}>
            {t('connections-new-how-connect')}
          </span>

          {howToLinks.length && (
            <ul>
              {howToLinks.map((l) => {
                return (
                  <li key={l.name}>
                    {(l.showDialog || !l.url) && (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a onClick={() => handleClickOpen(l)}>{'> ' + l.name}</a>
                    )}

                    {l.url && !l.showDialog && (
                      <a href={l.url} target="_blank" rel="noreferrer">
                        {'> ' + l.name}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>

      <Snackbar
        open={!!openAlert}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="success"
          sx={{ width: '100%' }}
        >
          {openAlert}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Connections;
