import {
  Typography,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  IconButton,
  Tooltip,
} from '@mui/material';
import { ChangeEvent, FC, FormEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Application.module.scss';
import Button from '../../UI/Button';
import { ActiveApplicationDto } from '../../../types/api';
import UBOPersonForm from './UBOPersonForm';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IUBOPerson } from '../../Common/AddressForm';
import axios from '../../../utils/axios';
import { parseApiError } from '../../../utils/utils';
import { useAppDispatch } from '../../../selectors/selectors';
import { addSnackbar } from '../../../store/slices/snackbarsSlice';
import Loading from '../../UI/Loading';

interface IApplicationUBIProps {
  updateApplicationData: (data: ActiveApplicationDto['application']) => void;
  applicationId: string;
}

function getInitialState(): IUBOPerson {
  return {
    firstName: '',
    lastName: '',
    dob: '',
    nationality: '',
    address: {
      line1: '',
      city: '',
      zip: '',
      country: '',
    },
  };
}

const ApplicationUBO: FC<IApplicationUBIProps> = ({
  updateApplicationData,
  applicationId,
}) => {
  const { t } = useTranslation();

  const UBO_VALUES = useMemo(
    () => [
      { value: 'self', label: t('application-ubo-solo') },
      {
        value: 'with',
        label: t('application-ubo-alongside'),
      },
      {
        value: 'else',
        label: t('application-ubo-else'),
      },
    ],
    [t]
  );

  const dispatch = useAppDispatch();

  const [selectedValue, setSelectedValue] = useState(UBO_VALUES[0].value);
  const [displayUboForm, setDisplayUboForm] = useState(false);
  const [formData, setFormData] = useState<IUBOPerson>(getInitialState());
  const [formDataErrors, setFormDataErrors] = useState<IUBOPerson>(
    getInitialState()
  );
  const [pep, setPep] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const isFormValid = (): boolean => {
    const errors: IUBOPerson = getInitialState();
    let formIsValid = true;

    if (!formData.firstName) {
      errors.firstName = t('registration.validation.firstname-required');
      formIsValid = false;
    }
    if (!formData.lastName) {
      errors.lastName = t('registration.validation.lastname-required');
      formIsValid = false;
    }
    if (!formData.dob) {
      errors.dob = t('registration.validation.birthday-required');
      formIsValid = false;
    }
    if (!formData.address.city) {
      errors.address.city = t('registration.validation.city-required');
      formIsValid = false;
    }

    if (!formData.address.zip) {
      errors.address.zip = t('registration.validation.zip-required');
      formIsValid = false;
    }
    if (!formData.address.line1) {
      errors.address.line1 = t('registration.validation.street-required');
      formIsValid = false;
    }
    if (!formData.address.country) {
      formIsValid = false;
      errors.address.country = t('registration.validation.country-required');
    }

    if (!formData.nationality) {
      formIsValid = false;
      errors.nationality = t('registration.validation.nationality-required');
    }

    setFormDataErrors(errors);

    return formIsValid;
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  const onSubmitNewPerson = async () => {
    const url = `applications/${applicationId}/step/ubo/person`;
    if (isFormValid()) {
      try {
        setLoading(true);
        await axios.post(url, { ...formData, pep });
        setFormData(getInitialState());
      } catch (error) {
        const { message } = parseApiError(error);
        dispatch(addSnackbar({ message, variant: 'error' }));
      } finally {
        setLoading(false);
      }
    }
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const selfUrl = `applications/${applicationId}/step/ubo/self`;
    const finishUrl = `applications/${applicationId}/step/ubo/finished`;

    //1. To submit the app
    if (selectedValue === 'self') {
      try {
        setLoading(true);

        await axios.post(selfUrl);
        const { data: finishResult } = await axios.post(finishUrl);

        updateApplicationData(finishResult);
      } catch (error) {
        const { message } = parseApiError(error);
        dispatch(addSnackbar({ message, variant: 'error' }));
      } finally {
        setLoading(false);
      }
    } //2. navigate to the form page
    else if (selectedValue !== 'self' && !displayUboForm) {
      if (selectedValue === 'with') {
        try {
          setLoading(true);

          await axios.post(selfUrl);
        } catch (error) {
          const { message } = parseApiError(error);
          dispatch(addSnackbar({ message, variant: 'error' }));
        } finally {
          setLoading(false);
        }
      }
      setDisplayUboForm(true);
    }
    //3 submit the new person
    else if (selectedValue !== 'self' && displayUboForm) {
      try {
        setLoading(true);

        await onSubmitNewPerson();
        const { data } = await axios.post(finishUrl);
        updateApplicationData(data);
      } catch (error) {
        const { message } = parseApiError(error);
        dispatch(addSnackbar({ message, variant: 'error' }));
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <Loading isLoading={loading} />;
  }

  return (
    <>
      <div className="gradient info">
        <h1>
          <span>
            {displayUboForm
              ? t('application-ubo-title-other')
              : t('application-ubo-title')}
          </span>
        </h1>
      </div>
      <Box p={2}>
        {!displayUboForm && (
          <Typography>{t('application-ubo-description')}</Typography>
        )}

        <form onSubmit={onSubmit}>
          {!displayUboForm ? (
            <FormControl sx={{ mt: 2 }} fullWidth>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedValue}
                name="radio-buttons-group"
                onChange={handleChange}
              >
                {UBO_VALUES.map(({ label, value }) => {
                  return (
                    <FormControlLabel
                      key={value}
                      value={value}
                      control={<Radio />}
                      label={label}
                      sx={{
                        border: selectedValue === value ? '2px solid' : 'none',
                        borderColor: 'primary.main',
                        borderRadius: 1,
                        padding: 1,
                        ml: '.1rem',
                        width: '100%',
                      }}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          ) : (
            <UBOPersonForm
              formData={formData}
              pep={pep}
              setFormData={setFormData}
              setPep={setPep}
              formErrors={formDataErrors}
            />
          )}

          <div className={styles['form-wrapper']}>
            <Stack direction={'row'} spacing={1}>
              {displayUboForm && (
                <div className={styles.button}>
                  <Button className="application" onClick={onSubmitNewPerson}>
                    {t('application-ubo-add-another')}
                  </Button>
                </div>
              )}
              <div className={styles.button}>
                <Button
                  type="submit"
                  className="application"
                  onClick={onSubmit}
                >
                  {t('application-button-continue')}
                </Button>
              </div>
              {/* {displayUboForm && (
                <Tooltip
                  title={t('back')}
                  onClick={() => {
                    setDisplayUboForm(false);
                    setFormDataErrors(getInitialState());
                  }}
                >
                  <IconButton
                    sx={{
                      marginLeft: 'auto !important',
                      marginTop: 'auto !important',
                    }}
                  >
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )} */}
            </Stack>
          </div>
        </form>
      </Box>
    </>
  );
};

export default ApplicationUBO;
